/* FONT FAMILY */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

@font-face {
  src: url("../fontfamily//times-new-roman/times-new-roman.ttf");
  font-family: TimesNewRoman;
}

:root {
  /* FONT SIZES */
  --font-2xs: 14px;
  --font-xs: 16px;
  --font-sm: 18px;
  --font-lg: 20px;
  --font-2lg: 26px;
  --font-md: 30px;
  --font-2xl: 49px;
  --font-xl: 42px;
  --font-xxl: 58px;
  --font-2xl: 35px;

  /* COLORS */
  --color-green: #08716c;
  --dark-green: #073937;
  --yellow: #e4b11d;
  --white: #ffffff;
  --pich: #fff9f4;
  --orange: #ffac69;
}

/* MEDIA QUERIES */
@media (max-width: 991.98) {
  :root {
    --font-xs: 13px;
    --font-sm: 15px;
    --font-lg: 16px;
    --font-md: 25px;
    --font-2xl: 40px;
    --font-xl: 38px;
    --font-xxl: 55px;
    --font-2xs: 12px;
  }
}

@media (max-width: 767.98) {
  :root {
    --font-xs: 12px;
    --font-sm: 13px;
    --font-lg: 14px;
    --font-md: 23px;
    --font-2xl: 38px;
    --font-xl: 36px;
    --font-xxl: 53px;
    --font-2xs: 11px;
    --font-2lg: 24px;
  }
}

@media (max-width: 575.98px) {
  :root {
    --font-xs: 12px;
    --font-sm: 13px;
    --font-lg: 14px;
    --font-md: 20px;
    --font-2xl: 35px;
    --font-xl: 32px;
    --font-2lg: 20px;
    --font-xxl: 41px;
    --font-2xs: 11px;
    --font-2xl: 30px;
    --font-2lg: 20px;
  }
}

/* CUSTOM CSS */

.bg-light-orange {
  background-color: var(--pich);
}

.bg-color-white {
  background-color: var(--white);
}

.text-primary {
  color: var(--dark-green) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.main-heading {
  font-family: TimesNewRoman;
  color: var(--dark-green);

  font-size: var(--font-xl);
  font-weight: bold;
  /* letter-spacing: 0.05em; */
}

/* FOOTER SECTION */
.footer-section {
  background-color: var(--dark-green);
}

.footer-images {
  width: 100px;
}

.footer-text {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: var(--white);
  font-size: var(--font-2xs);
}

.footer-icons {
  transition: 0.3s ease;
}

.footer-icons:hover {
  transform: scale(1.3);
}

.footer-list {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: var(--font-2xs);
  color: var(--white);
  position: relative;
  cursor: pointer;
}

.footer-list::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--white);
  transition: all 0.3s ease-in-out 0s;
}

.footer-list:hover::after {
  width: 100%;
}

.footer-headings {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: var(--font-2xs);
  color: var(--white);
}

.footer-input-wrapper {
  border-radius: 31px;
  background-color: var(--white);
  outline: none;
  border: 2px solid var(--white);
  display: flex;
  height: 60px;
}

.footer-input-wrapper input {
  border-radius: 31px;
  background-color: var(--white);
  outline: none;
  border: 0;
  color: var(--dark-green);
  width: 100%;
  padding-left: 20px;
}

.footer-btn {
  background-color: var(--yellow);
  border-radius: 31px;
  font-family: "Open Sans", sans-serif;
  border: 2px solid var(--yellow);
  color: var(--dark-green);
  font-weight: 600;
  right: 0;
  top: 0;
  height: 100%;
}

.footer-btn:hover {
  box-shadow: 0px 0px 10px var(--yellow);
}

.border-top {
  height: 3px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.border-bottom {
  height: 3px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.view-btn {
  position: absolute;
  top: 6%;
  display: flex;
  z-index: 3;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* SLIDER SECTION */
.slider-card {
  border: 1px solid rgba(7, 57, 55, 0.29);
}

.user-img {
  height: 89px;
  width: 89px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.slider-heading {
  color: var(--dark-green);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: var(--font-xs);
}

.slider-text {
  color: var(--dark-green);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: var(--font-xs);
}

/* MISS OUT SECTION */

.missout-text {
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-size: var(--font-xs);
  color: var(--dark-green);
}

.missout-input {
  border-bottom: 2px solid var(--dark-green);
  outline: none;
  color: var(--dark-green);
  font-weight: 600;
}

.missout-input::placeholder {
  color: var(--dark-green);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: var(--font-xs);
}

.subscribe-btn {
  background-color: var(--yellow);
  border: 2px solid var(--yellow);
  color: var(--dark-green);
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  transition: 0.4s;
  border-radius: 31px;
}

.subscribe-btn:hover {
  transform: scale(1.1);
  box-shadow: 1px 2px 20px var(--yellow);
}

.privacy-text {
  color: var(--dark-green);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.higlight-text {
  color: var(--dark-green);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.contact-text {
  font-size: var(--font-xs);
  color: var(--dark-green);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.highlight-contact-text {
  font-size: var(--font-xs);
  color: var(--dark-green);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  text-decoration: underline;
}

/* FEATURED HOME */
.featured-home-text {
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-size: var(--font-sm);
  color: var(--dark-green);
}

.get-started-text {
  text-decoration: underline;
  font-size: var(--font-xs);
  color: var(--dark-green);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.get-started-text:hover {
  opacity: 0.7;
  cursor: pointer;
}

.featured-home-heading {
  font-weight: 700;
  font-size: var(--font-md);
  color: var(--dark-green);
  font-family: TimesNewRoman;
}

.line {
  border-right: 1px solid rgba(7, 57, 55, 0.3);
}

.featured-home-heading {
  font-family: TimesNewRoman;
  color: var(--dark-green);
  font-size: var(--font-2lg);
  font-weight: 700;
}

.detail-text {
  color: var(--dark-green);
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  font-size: var(--font-xs);
  text-decoration: underline;
}

.ownjoy-section {
  background-color: var(--pich);
}

/* HERO SECTION */

.hero-text {
  color: var(--white);
  font-size: var(--font-xxl);
  font-family: TimesNewRoman;
  font-weight: 700;
  position: absolute;
  top: -10%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.down-arrow {
  position: absolute;
  top: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 0 2px var(--dark-green);
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  cursor: pointer;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--dark-green);
}

.image-text {
  position: absolute;
  bottom: 0;
  left: 8px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: var(--font-2xs);
  color: var(--white);
}

.features-cards {
  transition: 0.5s;
}

.features-cards:hover {
  transform: translateY(-10px);
}

.heart-icon-hover {
  transition: 0.3s ease;
}

.heart-icon-hover svg:hover {
  fill: var(--dark-green);
  cursor: pointer;
}

.list-items li {
  list-style: none !important;
  color: var(--white);
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: var(--white);
  transition: 0.3s ease;
  position: relative;
}

.list-items .underline-after-hover::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  transition: all 0.3s ease-in-out 0s;
}

/* UNDER LINE AFTER HOVER  */

.list-items .underline-after-hover:hover::after {
  width: 100%;
}

.header {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4 !important;
  transition: 0.5s ease;
}

.backdrop-filter-blur {
  backdrop-filter: blur(10px) !important;
}

.bg-opacity {
  background-color: rgba(7, 57, 55);
}

.brand {
  width: 93px;
  height: 47px;
  transition: 0.5s ease;
}

.hero-section {
  height: 100vh;
  width: 100%;
  position: relative;
}

.hero-section:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 162, 255, 0.219);
}

.object-cover {
  object-fit: cover;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-hidden-remove {
  overflow: unset;
}

.header-heart-icon-hover svg {
  transition: 0.3s;
}

.header-heart-icon-hover:hover svg {
  transform: translateY(-2px);
}

.sidebar {
  max-width: 320px;
  width: 100%;
  height: 100vh;
  background: #fcf5eb;
  box-shadow: 0 4px 15px rgb(0 0 0 / 20%);
  position: fixed;
  z-index: 5;
  top: 0px;
  right: 0px;
  overflow: auto;
}

.sidebar-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.247);
  z-index: 4;
}

.sidebar-heading {
  font-weight: 600;
  color: var(--dark-green);
  font-size: 14px;
  letter-spacing: 0.15rem;
}

.sidebar-nav-link .underline-hover {
  color: #251e35;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}

.sidebar-nav-link .underline-hover:hover {
  color: #e4b11d;
  opacity: 0.7;
}

/* UNDER LINE AFTER HOVER  */

.sidebar-nav-link .underline-hover:hover::after {
  width: 100%;
}

.cross-icon-sidebar {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.videoOverlay {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 10%;
  z-index: 3;
  object-position: bottom;
}

.w-35 {
  width: 35px;
}

/* FORGET TIMESHARE TABLE STYLE  */

.forget-timeshare-wrapper {
  background: var(--pich);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.bg-color-green {
  background-color: var(--color-green);
}

.bg-dark-green {
  background-color: var(--dark-green);
}

.mana-home-div {
  background-color: #ffac69;
  padding: 10px;
  font-size: var(--font-xs);
  color: var(--white);
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  width: 60%;
  height: 77px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resort-time-share-div {
  background-color: #ff831f;
  padding: 10px;
  font-size: var(--font-xs);
  color: var(--white);
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  width: 40%;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forget-table-list-item {
  font-size: var(--font-xs);
  color: var(--dark-green);
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  padding: 10px;
  height: 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.forget-table-list-item span {
  width: 33%;
  padding: 10px;
}

.right-plant-absolute-img {
  position: absolute;
  right: 2px;
  top: -100px;
}

.down-arrow {
  z-index: 3;
  scroll-behavior: smooth;
}

.down-arrow-animation {
  animation: down-arrow-animation 2s infinite;
}

@keyframes down-arrow-animation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.mt-m-6 {
  margin-top: -6px;
}

.leading-30 {
  line-height: 30px;
}

.curved-div-hero {
  width: 100%;
  height: 70px;
  background-color: var(--pich);
  position: absolute;
  border-radius: 40px;
  z-index: 1;
  bottom: -28px;
}

/* LEARN PAGE CSS */
.height-616 {
  height: 616px;
}

.table-responsive::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: transparent;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: var(--dark-green);
  border-radius: 10px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.learn-btn {
  background-color: var(--yellow);
  color: var(--dark-green);
  font-size: var(--font-xs);
  font-weight: 700;
  border-radius: 90px;
  border: 1px solid var(--yellow);
  transition: 0.3s;
}

.learn-btn:hover {
  box-shadow: 0px 0px 10px var(--yellow);
  transform: scale(1.1);
}

.booking-small-text {
  font-size: var(--font-2lg);
  color: var(--dark-green);
  font-weight: 700;
  font-family: TimesNewRoman;
}

.cost-card {
  background-color: var(--white);
  border-radius: 5px;
  transition: 0.4s;
}

.cost-card:hover {
  transform: translateY(-10px);
}

.cost-card-heading {
  color: var(--dark-green);
  font-weight: 700;
  font-family: TimesNewRoman;
  font-size: var(--font-2lg);
}

.cost-card-text {
  color: var(--dark-green);
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-size: var(--font-xs);
}

.table-head {
  background-color: var(--orange);
}

.table-head th {
  color: var(--white);
  font-family: "Open Sans", sans-serif;
  font-size: var(--font-xs);
  font-weight: 600;
}

.table-bg {
  background-color: var(--white);
}

.dots {
  height: 12px;
  width: 12px;
  background-color: #08716c;
  border-radius: 50%;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.rotate-active {
  transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transform: rotate(180deg);
}

.faq-wrapper {
  background: #ffffff;
  box-shadow: 3px 7px 25px rgba(0, 0, 0, 0.03);
  padding: 30px 40px;
  cursor: pointer;
}

.faq-answer {
  font-size: var(--font-lg);
  font-weight: 400;
  font-family: TimesNewRoman;
  color: var(--dark-green);
}

.hero-img-height {
  height: 616px;
}

/* AGENTS CSS */
.agents-heading {
  font-size: var(--font-2lg);
  font-weight: 700;
  font-family: TimesNewRoman;
  color: var(--dark-green);
}

.pitch-img {
  height: 476px;
  object-fit: cover;
  object-position: center;
}

.border-radius-15 {
  border-radius: 15px;
}

/*OUR STORY CSS */
.our-story-hero-img {
  height: 600px;
  object-fit: cover;
}

@media (max-width:575.98px) {
  .our-story-hero-img {
    height: 300px;
  }
}

.leadership-img {
  transition: 0.3s;
}

.our-story-img {
  height: 526px;
  object-fit: cover;
}

.leadership-img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.our-story-text {
  color: var(--white);
  font-family: TimesNewRoman;
  font-weight: 700;
  font-size: var(--font-2xl);
}

/* MEDIA QUERIES  */
@media (min-width: 1200px) {
  .max-w-xl-420 {
    max-width: 420px;
  }

  .max-w-xl-480 {
    max-width: 480px;
  }

  .max-w-xl-507 {
    max-width: 507px;
  }

  .max-w-xl-429 {
    max-width: 429px;
  }
}

@media (min-width: 992px) {
  .max-w-429 {
    max-width: 429px;
  }

  .max-w-442 {
    max-width: 442px;
  }

  .main-heading {
    line-height: 58px;
  }
}

@media (max-width: 991.98px) {
  .hero-img-height {
    height: unset;
  }
}

@media (min-width: 767.98px) {
  .max-width-525 {
    max-width: 525px;
  }
}

@media (max-width: 767.98px) {
  .view-btn {
    top: 10%;
  }

  .table-text {
    white-space: nowrap;
  }

  .faq-wrapper {
    padding: 20px 20px;
  }
}

@media (min-width: 576px) {
  .property-center-img {
    height: 100%;
    object-fit: cover;
    border-radius: 0px 90px 0px 0px;
  }

  .h-sm-216 {
    height: 216px;
  }
}

@media (max-width: 575.98px) {
  .white-space-xs-nowrap {
    white-space: nowrap;
  }

  .leadership-above-bgimage {
    height: 240px;
  }

  .leadership-above-bgimage img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media (max-width: 375px) {
  .down-arrow {
    top: 75%;
  }

  .view-btn {
    top: 18%;
  }
}